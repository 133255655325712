import { initializeApp } from "firebase/app";
import {
  getFirestore,
  addDoc,
  collection,
  Timestamp,
} from "firebase/firestore";
import { Label, TextInput, Button, Modal } from "flowbite-react";
import { useForm, SubmitHandler } from "react-hook-form";
import { firebaseConfig } from "../FirebaseConfig";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface IFormInput {
  name: string;
  createdAt: Date;
}

export const Game = () => {
  const { register, handleSubmit } = useForm<IFormInput>();
  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState("");
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    try {
      data.createdAt = Timestamp.now().toDate();
      await addDoc(collection(db, "winners"), data);
      setName(data.name);
      setOpenModal(true);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <div className="flex h-screen justify-center align-middle">
      <form
        className="flex max-w-md flex-col justify-center gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="pb-5">
          <div className="mb-2 block">
            <Label htmlFor="name" value="Your Name" />
          </div>
          <TextInput
            id="name"
            placeholder="Your Name"
            required
            {...register("name")}
          />
        </div>
        <Button type="submit">Submit</Button>
      </form>

      <Modal
        size="md"
        position="center"
        show={openModal}
        onClose={() => setOpenModal(false)}
      >
        <Modal.Body>
          <div className="flex justify-center space-y-6">Good luck {name}!</div>
        </Modal.Body>
        <Modal.Footer className="flex justify-center">
          <Button
            onClick={() => {
              navigate(0);
            }}
          >
            Exit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
