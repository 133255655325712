export const Map = () => {
  return (
    <iframe
      title="Google Map"
      width="100%"
      height="100%"
      frameBorder="0"
      src="https://www.google.com/maps/embed/v1/place?q=andazsingapore&key=AIzaSyCfxuONJTlmlLMe199apFQl7IpPTZvjYtY"
      allowFullScreen
      loading="lazy"
    ></iframe>
  );
};
