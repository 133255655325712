import Lottie from "react-lottie-player";
import { Map } from "./Map";
import TropicalLottie from "./icons/tropical.json";
import EggLottie from "./icons/egg.json";
import LoveLetterLottie from "./icons/love-letter.json";

export const Event = () => {
  return (
    <div className="flex h-fit w-full flex-col items-center justify-center rounded-md border-2 border-solid border-black bg-slate-200 align-middle shadow-2xl lg:h-full">
      <div className="flex flex-row pt-5 text-5xl">
        Where <div className="animate-bounce">📍</div>
      </div>

      <div className="m-3 w-5/6 border-t border-gray-400" />

      <div className="grid w-5/6 grid-cols-2 flex-row flex-wrap items-start justify-center gap-10 text-wrap pb-5 lg:grid-cols-4">
        <div className="flex flex-col items-center justify-center">
          <div className="h-12 w-12 text-3xl">📅</div>
          <div className="text-xl font-extrabold">Sunday, 7 July 2024</div>
          <div className="text-xl font-extrabold">
            星期天，七月七日 二零二四
          </div>
        </div>

        <div className="flex flex-col items-center justify-center">
          <div>
            <Lottie loop animationData={EggLottie} play className="w-12" />
          </div>
          <div className="animate-wiggle text-xl font-bold animate-infinite">
            Lunch
          </div>
          <div className="animate-wiggle text-xl font-bold animate-infinite">
            午宴
          </div>
          <div className="text-xl underline decoration-pink-500 decoration-4">
            11:45 AM
          </div>
        </div>

        <div className="flex flex-col items-center justify-center">
          <div>
            <Lottie loop animationData={TropicalLottie} play className="w-12" />
          </div>
          <div className="font-bold">Pre-event Reception</div>
          <div className="font-bold">接待宾客</div>
          <div>11:00 AM</div>
        </div>

        <div className="flex flex-col items-center justify-center">
          <Lottie loop animationData={LoveLetterLottie} play className="w-12" />
          <div className="font-bold">Solemnisation + Tea Ceremony</div>
          <div className="font-bold">注册 + 敬茶仪式</div>
          <div className="italic">Feel free to join us!</div>
          <div>9:45 AM</div>
        </div>
      </div>

      <div className="px-2 text-3xl font-bold underline decoration-pink-500">
        Garden Studio @ Andaz Singapore Level 3
      </div>

      <div className="h-96 w-full flex-auto px-5 py-5 lg:w-5/6">
        <Map />
      </div>
    </div>
  );
};
