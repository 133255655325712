import { Label, TextInput, Button, Checkbox } from "flowbite-react";
import { useState } from "react";
import { CiCirclePlus, CiCircleMinus } from "react-icons/ci";
import { AnimatePresence, motion } from "framer-motion";
import { initializeApp } from "firebase/app";
import { addDoc, collection, getFirestore } from "firebase/firestore/lite";
import { Modal } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { AddToCalendarButton } from "add-to-calendar-button-react";

export const RSVP = () => {
  const defaultFormData = {
    name: "",
    guests: [] as { name: string; meal: string }[],
    guestOf: "Groom" as "Groom" | "Bride",
    veg: false,
  };

  const navigate = useNavigate();
  const [guestCount, setGuestCount] = useState(0);
  const [formData, setFormData] = useState(defaultFormData);
  const [submittedName, setSubmittedName] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const firebaseConfig = {
    apiKey: "AIzaSyD9qnldIS_r_kVa1ndqmS2jw35Vw4FSlLU",
    authDomain: "wedding-rsvp-310d3.firebaseapp.com",
    projectId: "wedding-rsvp-310d3",
    storageBucket: "wedding-rsvp-310d3.appspot.com",
    messagingSenderId: "953915096293",
    appId: "1:953915096293:web:2a49a15f60b11246f6280c",
    measurementId: "G-CL9FQCVY89",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    console.log("e", e);
    console.log("formData", formData);

    try {
      await addDoc(collection(db, "guests"), formData);
      setSubmittedName(formData.name);
      // Reset the form after successful submission
      setFormData(defaultFormData);
      setGuestCount(0);
      setOpenModal(true);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const displayGuestInputs = () => {
    const guestInputs = [];

    for (let i = 0; i < guestCount; i++) {
      guestInputs.push(
        <motion.div
          key={`guestInput${i}`}
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="flex flex-row items-center justify-center align-middle">
            <TextInput
              id={`addGuest${i}`}
              type="text"
              required
              placeholder="Name"
              className="pr-3 md:w-3/4 lg:w-4/5"
              sizing="lg"
              onChange={(event) => {
                const tempGuests = [...formData.guests];
                tempGuests[i].name = event.target.value;
                setFormData({
                  ...formData,
                  guests: tempGuests,
                });
              }}
            />

            <Button.Group>{displayMealsButtons(i)}</Button.Group>
          </div>
        </motion.div>,
      );
    }

    return guestInputs;
  };

  const displayMealsButtons = (guestIndex: number) => {
    const mealButtons = [];
    const meals = ["CHINESE", "VEG", "KID"];
    for (let i = 0; i < meals.length; i++) {
      mealButtons.push(
        <Button
          key={`meals${i}`}
          type="button"
          value={meals[i]}
          color={
            formData.guests[guestIndex].meal === meals[i] ? "blue" : "gray"
          }
          onClick={() => {
            const tempGuests = [...formData.guests];
            tempGuests[guestIndex].meal = meals[i];
            setFormData({
              ...formData,
              guests: tempGuests,
            });
          }}
        >
          {meals[i] === "CHINESE" && "Chinese"}
          {meals[i] === "VEG" && "Veg"}
          {meals[i] === "KID" && "Kid"}
        </Button>,
      );
    }

    return mealButtons;
  };

  return (
    <form
      className="flex w-full flex-col items-center justify-center gap-4"
      onSubmit={handleSubmit}
    >
      <div className="w-full">
        <div className="mb-2 block">
          <Label
            className="text-lg text-blue-900"
            htmlFor="name"
            value="Your Name"
          />
        </div>
        <div className="flex w-full flex-row items-center justify-center">
          <TextInput
            id="name"
            type="text"
            placeholder="Name"
            required
            className="pr-3 md:w-2/4 lg:w-1/5"
            sizing="lg"
            onChange={(event) => {
              setFormData({
                ...formData,
                name: event.target.value,
              });
            }}
          />
          <Checkbox
            id="veg"
            className="mr-1 h-5 w-5 lg:mr-2"
            onChange={(event) => {
              setFormData({
                ...formData,
                veg: event.target.checked,
              });
            }}
          />
          <Label htmlFor="veg">Vegetarian Course</Label>
        </div>
      </div>

      <div>
        <div className="mb-2 flex flex-row items-center justify-center gap-3">
          <Label htmlFor="addGuest" value={`${guestCount} Additional Guests`} />
          <CiCircleMinus
            className={`size-8 ${guestCount > 0 ? "cursor-pointer hover:animate-pulse" : ""}`}
            onClick={() => {
              guestCount > 0 && setGuestCount(guestCount - 1);
              const tempGuests = formData.guests;
              tempGuests.pop();
              setFormData({
                ...formData,
                guests: tempGuests,
              });
            }}
            color={guestCount === 0 ? "gray" : ""}
          />
          <CiCirclePlus
            className="size-8 cursor-pointer hover:animate-pulse"
            onClick={() => {
              setGuestCount(guestCount + 1);
              const tempGuests = formData.guests;
              tempGuests.push({ name: "", meal: "CHINESE" });
              setFormData({
                ...formData,
                guests: tempGuests,
              });
            }}
          />
        </div>

        <div className="flex flex-col items-center justify-center gap-3">
          <AnimatePresence>{displayGuestInputs()}</AnimatePresence>
        </div>
      </div>

      <div className="mb-3">
        <div className="mb-2 block">
          <Label htmlFor="guestOf" value="Guest of?" />
        </div>
        <Button.Group>
          <Button
            onClick={() =>
              setFormData({
                ...formData,
                guestOf: "Groom",
              })
            }
            color={formData.guestOf === "Groom" ? "blue" : "gray"}
          >
            Groom
          </Button>
          <Button
            onClick={() =>
              setFormData({
                ...formData,
                guestOf: "Bride",
              })
            }
            color={formData.guestOf === "Bride" ? "blue" : "gray"}
          >
            Bride
          </Button>
        </Button.Group>
      </div>

      <Button type="submit" className="w-64 lg:w-96">
        Submit
      </Button>

      <AddToCalendarButton
        name="Wedding of Ian & Wei Tian"
        startDate="2024-07-07"
        startTime="11:45"
        endDate="2024-07-07"
        endTime="17:45"
        options={[
          "Apple",
          "Google",
          "Outlook.com",
          "Microsoft365",
          "MicrosoftTeams",
          "iCal",
        ]}
        timeZone="Asia/Singapore"
        location="Andaz Singapore - A Concept by Hyatt, 5 Fraser St, Singapore 189354"
        hideBranding
      ></AddToCalendarButton>

      <Modal
        size="md"
        position="center"
        show={openModal}
        onClose={() => setOpenModal(false)}
      >
        <Modal.Body>
          <div className="flex justify-center space-y-6">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              Dear {submittedName}, we have received your RSVP, looking forward
              to seeing you on 07.07!
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex justify-center">
          <Button
            onClick={() => {
              navigate(0);
            }}
          >
            💖
          </Button>
        </Modal.Footer>
      </Modal>
    </form>
  );
};
