import React, { CSSProperties } from "react";
import { Parallax } from "react-parallax";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import { Reveal } from "./Reveal";
import { RSVP } from "./RSVP";
import { scroller } from "react-scroll";
import { Button } from "flowbite-react";
import { Event } from "./Event";
import Spline from "@splinetool/react-spline";
import Lottie from "react-lottie-player";
import PencilLottie from "./icons/pencil.json";
import { HowToGetThere } from "./HowToGetThere";

const styles: CSSProperties = {
  fontFamily: "Quicksand",
  textAlign: "center",
};

const MainPage = () => {
  const scrollToSection = (section: string) => {
    scroller.scrollTo(section, {
      offset: -50, // Optional offset for precise positioning
    });
  };

  return (
    <div style={styles} className="bg-red-100">
      <Parallax
        bgImage="https://i.imgur.com/UPNQE7g.jpg"
        bgImageStyle={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        strength={500}
        blur={7}
        className="rounded-b-section"
      >
        <div className="flex h-screen flex-col items-center justify-center py-5 selection:bg-fuchsia-300 selection:text-fuchsia-900 landscapes:h-fit">
          <Reveal width="100%">
            <div
              className="p-1 text-3xl lg:text-5xl"
              style={{ fontFamily: "Quicksand", color: "white" }}
            >
              We're Getting Married!
            </div>
          </Reveal>

          <Reveal width="100%">
            <div
              className="p-10 text-8xl text-gray-200 lg:text-9xl"
              style={{ fontFamily: "La Belle Aurore" }}
            >
              ian & wei tian
            </div>
          </Reveal>

          <Reveal width="fit-content">
            <FlipClockCountdown
              to={new Date("07/07/2024").getTime()}
              labelStyle={{
                color: "rgb(229 231 235)",
              }}
              digitBlockStyle={{
                height: "10vh",
                minHeight: 75,
                maxHeight: 150,
                width: "9vw",
                maxWidth: 75,
                backgroundColor: "white",
                color: "black",
                fontSize: "5vw",
              }}
              separatorStyle={{
                color: "grey",
              }}
              dividerStyle={{
                color: "grey",
              }}
            />
          </Reveal>

          <Reveal width="100%">
            <div className="p-5 text-3xl text-gray-600">to</div>
          </Reveal>

          <Reveal width="100%">
            <div
              style={{ fontFamily: "La Belle Aurore" }}
              className="inline-block bg-gradient-to-r from-green-300 via-pink-500 to-indigo-400 bg-clip-text p-5 text-7xl font-bold text-transparent"
            >
              07.07.2024
            </div>
          </Reveal>

          <Reveal width="fit-content">
            <div className="flex flex-row gap-10">
              <Button
                color="pink"
                pill
                onClick={() => scrollToSection("scrollRSVP")}
                className="w-16 px-16 py-4"
              >
                RSVP
              </Button>
              <Button
                color="pink"
                pill
                onClick={() => scrollToSection("scrollLocation")}
                className="w-16 px-16 py-4"
              >
                Event
              </Button>
            </div>
          </Reveal>
        </div>
      </Parallax>

      <div id="scrollRSVP" />

      <div className="mt-24 flex flex-col justify-center rounded-b-section bg-red-100 selection:bg-fuchsia-300 selection:text-fuchsia-900">
        <Reveal width="100%">
          <div
            style={{ fontFamily: "Damion" }}
            className="flex h-24 items-center justify-center text-center text-7xl"
          >
            RSVP{" "}
            <div>
              <Lottie
                speed={0.5}
                loop
                animationData={PencilLottie}
                play
                className="w-24 pl-5"
              />
            </div>
          </div>
        </Reveal>
        <Reveal width="100%">
          <div className="flex justify-center p-2 align-middle">
            <RSVP />
          </div>
        </Reveal>
      </div>

      <div className="h-48 bg-slate-300">
        <div className="h-24 rounded-b-section bg-red-100"></div>
      </div>

      <div
        id="scrollLocation"
        className="flex flex-col justify-center bg-slate-300 selection:bg-fuchsia-300 selection:text-fuchsia-900"
      >
        <Reveal width="100%">
          <div className="flex h-fit justify-center p-2 align-middle lg:p-16">
            <Event />
          </div>
        </Reveal>
      </div>

      <div className="h-48 bg-[#002b99]">
        <div className="h-24 rounded-b-section bg-slate-300"></div>
      </div>

      <div className="flex flex-col justify-center bg-[#002b99] selection:bg-fuchsia-300 selection:text-fuchsia-900">
        <Reveal width="100%">
          <div className="flex h-fit justify-center p-2 align-middle lg:p-16">
            <HowToGetThere />
          </div>
        </Reveal>
      </div>

      <div className="h-48 bg-[#E0C7A0]">
        <div className="h-24 rounded-b-section bg-[#002b99]"></div>
      </div>

      <div>
        <div className="bg-[#E0C7A0] italic">Pinch/click to drag & zoom!</div>
        <div className="h-96">
          <Spline scene="https://prod.spline.design/hNDn-GrgHuvTydlo/scene.splinecode" />
        </div>
      </div>
    </div>
  );
};

export default MainPage;
