import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  onSnapshot,
  Timestamp,
} from "firebase/firestore";
import { firebaseConfig } from "../FirebaseConfig";
import { useEffect, useState } from "react";
import { Card } from "flowbite-react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { format } from "date-fns";
import { Reveal } from "../Reveal";

export const GameResults = () => {
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const [winners, setWinners] = useState<
    { name: string; createdAt: Timestamp }[]
  >([]);

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "winners"), (snapshot) => {
      setWinners(
        snapshot.docs
          .map((doc) => ({
            name: doc.data().name,
            createdAt: doc.data().createdAt,
          }))
          .sort((a, b) => a.createdAt - b.createdAt),
      );
    });

    return () => unsub();
  }, [db]);

  const determineWinners = (index: number) => {
    switch (index) {
      case 0:
        return "bg-gold";
      case 1:
        return "bg-silver";
      case 2:
        return "bg-bronze";
      default:
        return "";
    }
  };

  return (
    <div className="flex h-screen w-full flex-col flex-wrap place-content-start items-start justify-start bg-pink-200 p-8">
      {winners.map((winner, index) => (
        <Reveal>
          <Card
            className={`m-2 ${determineWinners(index)}`}
            key={winner.createdAt.toMillis()}
          >
            <div className="flex flex-row">
              <div>
                <LazyLoadImage
                  height={75}
                  width={75}
                  src={`https://rickandmortyapi.com/api/character/avatar/${index + 1}.jpeg`}
                  className="rounded-full"
                />
              </div>
              <div className="flex flex-col justify-center pl-3">
                <span className="font-bold">{winner.name}</span>
                <div>
                  Time:&nbsp;
                  {format(winner.createdAt.toDate(), "HH:mm:ss.SSS")}
                </div>
              </div>
            </div>
          </Card>
        </Reveal>
      ))}
    </div>
  );
};
