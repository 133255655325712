import { Card } from "flowbite-react";
import ThinkingLottie from "./icons/thinking.json";
import Lottie from "react-lottie-player";
import YouTube from "react-youtube";

export const HowToGetThere = () => {
  return (
    <div className="flex h-fit w-full flex-col content-center items-center rounded-md border-2 border-solid border-black bg-white shadow-2xl lg:h-full">
      <div className="flex flex-row gap-3 pt-5 text-3xl lg:text-5xl landscapes:text-5xl">
        How to get there?{" "}
        <Lottie
          loop
          animationData={ThinkingLottie}
          play
          className="w-8 lg:w-12 landscapes:w-12"
        />
      </div>
      <div className="m-3 w-5/6 border-t border-gray-400" />

      <div className="flex flex-col flex-wrap justify-center gap-3 px-3 py-2">
        <Card>
          <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Bugis MRT Station (Exit F)
          </h5>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            Closest To Bugis Downtown Line
          </p>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            Video by Andaz:
            <YouTube
              videoId="9LhrAn277AI"
              iframeClassName="h-48 md:h-96 lg:h-[500px] w-full lg:w-3/4"
              className="flex justify-center"
            />
          </p>
          <p className="text-left font-normal text-gray-700 dark:text-gray-400">
            <span className="font-bold text-green-700">
              East West Line (Green)
            </span>
            <br />
            If you exit Bugis station from East West line, turn right and walk
            straight to the end to arrive at the Downtown line. Look for Exit F
            located on your left.
          </p>
          <p className="text-left font-normal text-gray-700 dark:text-gray-400">
            <span className="font-bold text-blue-700">
              Downtown Line (Blue)
            </span>
            <br />
            If you exit Bugis station from downtown line, Exit F is on your
            right.
          </p>
          <p className="text-left font-normal text-gray-700 dark:text-gray-400">
            <span className="underline">From Exit F,</span>
            <ul className="list-inside list-disc">
              <li>You will see Killiney and 7-eleven next to each other</li>
              <li>Turn right and head straight along the underpass</li>
              <li>
                Look out for a door entrance to the lift lobby (refer to
                picture)
              </li>
              <li>
                Enter the door and you will arrive at the Andaz’s B3 lift lobby
              </li>
              <li>Take the lift and head up to Level 3</li>
            </ul>
            <span className="font-bold">
              *Do not take any escalator up to the ground floor; entrance to
              lift lobby is via B3 underpass
            </span>
          </p>
        </Card>
        <Card>
          <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Nearest Bus Stop
          </h5>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            Parkview Square (01139) on North Bridge Road, 2 minutes' walk.
          </p>
        </Card>
      </div>

      <img
        src="https://i.imgur.com/52nm029.png"
        alt="map"
        className="object-cover p-3"
      />

      <p className="w-full p-3 font-normal text-gray-700 dark:text-gray-400">
        Directions By Car (Video by Andaz):
        <YouTube
          videoId="ZP5pdfN24ms"
          iframeClassName="h-48 md:h-96 lg:h-[500px] w-full xl:w-1/2"
          className="flex justify-center"
        />
      </p>

      <div className="pb-3 text-blue-400 underline hover:text-blue-800">
        <a
          href="https://www.hyatt.com/andaz/sinaz-andaz-singapore/hotel-info/parking-and-transportation"
          target="_blank"
          rel="noopener noreferrer"
        >
          Andaz Website Directions
        </a>
      </div>
    </div>
  );
};
