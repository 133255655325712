import { initializeApp } from "firebase/app";
import { collection, getDocs, getFirestore } from "firebase/firestore/lite";
import { firebaseConfig } from "./FirebaseConfig";
import { useEffect, useState } from "react";
import { List, ListItem, Table } from "flowbite-react";

export const GuestList = () => {
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const [guestList, setGuestList] = useState<
    {
      name: string;
      guests: {
        name: string;
        meal: string;
      }[];
      guestOf: "Groom" | "Bride";
      veg: boolean;
    }[]
  >([]);

  useEffect(() => {
    getDocs(collection(db, "guests")).then((querySnapshot) => {
      const tempGL: any = [];
      querySnapshot.docs.map((doc) => {
        tempGL.push(doc.data() as any);
        return console.log(doc.id, " => ", doc.data());
      });
      setGuestList(tempGL);
    });
  }, [db]);

  return (
    <div className="overflow-x-auto">
      <Table>
        <Table.Head>
          <Table.HeadCell>#</Table.HeadCell>
          <Table.HeadCell>Guest Name</Table.HeadCell>
          <Table.HeadCell>Additional Guests</Table.HeadCell>
          <Table.HeadCell>Vegetarian</Table.HeadCell>
          <Table.HeadCell>Guest Of?</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {guestList.map((guest, index) => (
            <Table.Row>
              <Table.Cell>{index + 1}</Table.Cell>
              <Table.Cell>{guest.name}</Table.Cell>
              <Table.Cell>
                {guest.guests?.length > 0 ? (
                  <List>
                    {guest.guests.map((addGuest) => (
                      <ListItem>
                        {addGuest.name} ({addGuest.meal})
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  "N.A."
                )}
              </Table.Cell>
              <Table.Cell>{guest.veg ? "Yes" : "No"}</Table.Cell>
              <Table.Cell>{guest.guestOf}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};
