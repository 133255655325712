import { miyagi } from "ldrs";

export const FullPageLoader = () => {
  miyagi.register();

  return (
    <div className="flex h-screen w-screen items-center justify-center bg-gradient-to-r from-pink-50 to-pink-300">
      <l-miyagi size="80" stroke="5" speed="1" color="#A5A7B6" />
    </div>
  );
};
