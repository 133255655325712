import { useState } from "react";
import "./App.css";
import MainPage from "./main/MainPage";
import { useTimeout } from "usehooks-ts";
import "ldrs/miyagi";
import { FullPageLoader } from "./main/FullPageLoader";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { GuestList } from "./main/GuestList";
import { Game } from "./main/game/Game";
import { GameResults } from "./main/game/GameResults";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const App = () => {
  const [visible, setVisible] = useState(true);

  const hide = () => {
    setVisible(false);
  };

  useTimeout(hide, 1500);

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <div>
          <Routes>
            <Route
              path="/"
              element={visible ? <FullPageLoader /> : <MainPage />}
            />
            <Route path="/admin3003" element={<GuestList />} />
            <Route path="/game" element={<Game />} />
            <Route path="/gameresults" element={<GameResults />} />
          </Routes>
        </div>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
